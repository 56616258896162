
.Swiper-Page{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 42vh;
   margin-top: 2.3vh;
  width: 100vw;
  overflow: hidden;
  transition: 800ms;
  border: none;
  display: absolute;
 
}





.swiper {
  width: 260%;
  position: relative;
  right: 80vw;
  height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: var(--doc-height) !important;
}

.swiper-slide {
  
    border-radius: 20px !important;
  background-position: center;
  background-size: cover;
  width: 87vw !important;
  display: flex;

  
  
  
}

.background-gradient{
  background: rgb(237,237,237);
  background: linear-gradient(101deg, rgba(237,237,237,1) 0%, rgba(255,255,255,1) 100%);
}