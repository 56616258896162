.hero{
    width: 100%;
    margin-top: 0vh;
    height: 78.2vh;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #000 !important;
   

    &__img{
        top: 10vh;
        right: 8vw;
        float: right;
        width: auto;
        height: 90vh;
        position: absolute;
        background-position: center;
        background-size: cover;
        border-bottom-right-radius: 100px;
       
        z-index: -1;
        object-fit: cover;
    }

    &__main{
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4vh;
        position: absolute;
        
        margin-top: 16vh;
     
       

        &__header{
            
            position: relative;
            margin-bottom: 5vh;
            text-align: start;
            font-weight: normal;   
            font-weight: 100;
            
            
            &__title{
                opacity: 0;
                position: relative;
                animation: leftToRightTitle 900ms ease 0s forwards;
                

            }

           

           
            &__fade-in{
                position: relative;
               
                font-weight: bold;
                left: 0vw; 
                text-align: center;
                

                p {
                    font-weight: lighter !important;
                    font-weight: bold !important;
                    animation: fadeEffect 12s linear infinite 0s;
                    -ms-animation: fadeEffect 12s  linear infinite 0s;
                    -webkit-animation: fadeEffect 12s  linear infinite 0s;
                    opacity: 0;
                    position: absolute;
                    width: auto!important;
                }

                p:nth-child(2) {
                    animation-delay: 2s;
                    -ms-animation-delay: 2s;
                    -webkit-animation-delay: 2s;
                    width: auto !important;
                    font-weight: bold !important;
                }

                p:nth-child(3) {
                    animation-delay: 4s;
                    -ms-animation-delay: 4s;
                    -webkit-animation-delay: 4s;
                    width: auto !important;
                    font-weight: bold !important;
                }
                p:nth-child(4) {
                    animation-delay: 6s;
                    -ms-animation-delay: 6s;
                    -webkit-animation-delay: 6s;
                    width: auto !important;
                    font-weight: bold !important;
                }
                p:nth-child(5) {
                    animation-delay: 8s;
                    -ms-animation-delay: 8s;
                    -webkit-animation-delay: 8s;
                    width: auto !important;
                    font-weight: bold !important;
                }
                p:nth-child(6) {
                    animation-delay: 10s;
                    -ms-animation-delay: 10s;
                    -webkit-animation-delay: 10s;
                    width: auto !important;
                    font-weight: bold !important;
                }

          
              


            }


        }
    
        &__footer{
            margin-top: 3vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 2vh;
           

            p {
                font-weight: 500;
                letter-spacing: 0.3px;
                
                text-align: start;
                width: 65%
                ;
              
            }
      
        }
       
    }


}





@keyframes fadeEffect {
    0% {
        opacity: 0;
    }
    2% {
        opacity: 0;
        -webkit-transform: translatey(20px);
    }
    7% {
        opacity: 1;
        -webkit-transform: translatey(0px);
    }
    15% {
        opacity: 1;
        -webkit-transform: translatey(0px);
    }
    20% {
        opacity: 0;
        -webkit-transform: translatey(-20px);
        
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
    
}



@media screen and (max-width: 1080px) {
    
    .hero{
        width: 100%;
        height: auto;
        margin-top: 10vh;
        padding-block: 6vh;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding-left: 4vw;
        font-weight: bold;
    
        &__img{
            width: 100%;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
            z-index: -1;
            object-fit: cover;
        }
    
        &__main{
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            margin-right: 0;
            margin-top: 0;
            font-weight: bold;
           
    
            &__header{
                width: 95%;
                color: #000 !important;
                position: relative;
                margin-bottom: 6vh;
                text-align: center;
                 
                font-weight: 100;
                font-weight: bold;
                
                &__title{
                    position: relative;
                    right: 0vw;
                    line-height: 49px;
                    font-weight: bold !important;
                }
               
    
                &__fade-in{
                    position: relative;
                   
                    font-weight: bold;
                    left: 0vw; 
                    text-align: center;
                    
    
                    p {
                        font-weight: lighter !important;
                        font-weight: bold !important;
                        animation: fadeEffect 12s linear infinite 0s;
                        -ms-animation: fadeEffect 12s  linear infinite 0s;
                        -webkit-animation: fadeEffect 12s  linear infinite 0s;
                        opacity: 0;
                        position: absolute;
                        width: auto!important;
                    }
    
                    p:nth-child(2) {
                        animation-delay: 2s;
                        -ms-animation-delay: 2s;
                        -webkit-animation-delay: 2s;
                        width: auto !important;
                        font-weight: bold !important;
                    }
    
                    p:nth-child(3) {
                        animation-delay: 4s;
                        -ms-animation-delay: 4s;
                        -webkit-animation-delay: 4s;
                        width: auto !important;
                        font-weight: bold !important;
                    }
                    p:nth-child(4) {
                        animation-delay: 6s;
                        -ms-animation-delay: 6s;
                        -webkit-animation-delay: 6s;
                        width: auto !important;
                        font-weight: bold !important;
                    }
                    p:nth-child(5) {
                        animation-delay: 8s;
                        -ms-animation-delay: 8s;
                        -webkit-animation-delay: 8s;
                        width: auto !important;
                        font-weight: bold !important;
                    }
                    p:nth-child(6) {
                        animation-delay: 10s;
                        -ms-animation-delay: 10s;
                        -webkit-animation-delay: 10s;
                        width: auto !important;
                        font-weight: bold !important;
                    }
    
              
                  
    
    
                }
    
    
            }
        
            &__footer{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2vh;
    
                p {
                    font-weight: 500;
                    color: #000;
                    text-align: start;
                    width: 100%;
                    line-height: 23px;
                    
            
                  
                }
          
            }
           
        }
    
    
    }
    
    

    @keyframes leftToRightTitle {
        0% {
            opacity: 0;
            left: -25%;
        }
        100% {
            opacity: 1;
            left: 0vw;
        }
    
        
    }

    @keyframes RightToLeftTitle {
        0% {
            opacity: 0;
            left: 25%;
        }
        100% {
            opacity: 1;
            left: 0vw;
        }

    }
    
    
    
    @keyframes fadeEffect {
        0% {
            opacity: 0;
        }
        7% {
            opacity: 0;
            -webkit-transform: translatey(20px);
        }
        12% {
            opacity: 1;
            -webkit-transform: translatey(0px);
        }
        20% {
            opacity: 1;
            -webkit-transform: translatey(0px);
        }
        25% {
            opacity: 0;
            -webkit-transform: translatey(-20px);
            
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
        
    }
    
    
    
}