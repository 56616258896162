 
    .dropdown-page-header{
        display: flex;
        flex-direction: column;
        gap: 1.4vh;
        width: 100.5%;
        font-family: "ProductSans-Light";
       
        


        span{
            border-bottom: 1px solid #000000a9;
        }

        &__yellow{
            border-radius: 20px;
            width: fit-content !important;
            padding: 8px 16px !important;
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: rgb(255, 208, 0);
            color: black;
            font-size: 15px;
            letter-spacing: 0.5px;
            transition: 1000ms;

            p{
                animation: FadeIn-birads 3s;
            }

            @keyframes FadeIn-birads {
                from {
                    opacity: 0;
                }
                
                to {
                    opacity: 1;
                }
            }
          
        }

        &__title{
            color: #000000;
            font-size: 42px;
            line-height: 48px;
            width: 100%;
            font-family: "Optima";
        }

        &__subtitle{
            width: 85%;
            color: #000000d6;
            font-size: 16px;
            letter-spacing: 0.3px;
            line-height: 18px;
            line-height: 21px;
            font-family: "ProductSans-Light";
        }

        &__name-date{
            margin-top: 2vh;
            display: flex;
            gap: 1vh;
            width: 100%;
           
            font-size: 14px;
            gap: 10px;
            justify-content:none;
            align-items: center;

            &__name{
                color: #000000d6;
                
                font-size: 14px;
                letter-spacing: 0.3px;
            }


            &__date{
                color: #000000a9;
                font-size: 14px;
                letter-spacing: 0.3px;
            }

            &__circle{
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #a4cf0b;
            }
          
        }

        &__checked{
            color: #000000d6;
            display: flex;
            gap: 1vh;
            width: 100%;
            font-size: 14px;
            align-items: center;
        }

        &__social{
            margin-top: 28px;
            display: flex;
            gap: 11px;
            width: fit-content;
            align-self: flex-end;
            position: relative;
            right: 10.8%;
            bottom: 8.5vh;

            &__logo{
                width: 24px;
                height: 24px;
                cursor: pointer;
                color: #000000;
            }
        }
    



    }


@media screen and (max-width: 1080px) {
    
    .dropdown-page-header{
        display: flex;
        flex-direction: column;
        gap: 2vh;
        width: 94.5%;
        font-family: "ProductSans-Light";
        padding-bottom: 3vh;


        span{
            border-bottom: 1px solid #000000a9;
        }

        &__yellow{
            border-radius: 20px;
            width: fit-content;
            padding: 4px 18px !important;
            align-items: center;
            justify-content: center;
            display: flex;
            background-color: rgb(255, 208, 0);
            color: black;
            font-size: 15px;
            ;
            
        }

        &__title{
            width: 100%;
            color: #000000;
            font-size: 36px;
            line-height: 40px;
        }

        &__subtitle{
            color: #000000d6;
            font-size: 16px;
            letter-spacing: 0.3px;
            line-height: 18px;
            line-height: 21px;
            width: 100%;
        }

        &__name-date{
            display: flex;
            flex-direction: column;
            gap: 1vh;
            width: 94vw;
            font-size: 13px;
            justify-content: space-between;
            align-items: flex-start;

            &__name{
             
                color: #000000d6;
                font-size: 13px;
                letter-spacing: 0.3px;
            }


            &__date{
                color: #000000a9;
                font-size: 13px;
                letter-spacing: 0.3px;
            }

            &__circle{
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #a4cf0b;
            }
          
        }

        &__checked{
            color: #000000d6;
            display: flex;
            gap: 1vh;
            width: 100%;
            font-size: 14px;
            align-items: center;
            position: relative;
            right: 2vw;
        }

        &__social{
            margin-top: 17px;
            display: flex;
            gap: 11px;
            width: fit-content;
            align-self: flex-start;
            right: 0;
            bottom: 0;

            &__logo{
                width: 24px;
                height: 24px;
                cursor: pointer;
                color: #000000;
            }
        }
    



    }

    
}
