.all-one-service {
  width: 100%;
  height: 70vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "ProductSans-Light";
  gap: 5vh;
  padding-top: 6vh;
}
.all-one-service__main {
  width: 79.69%;
  height: 100%;
  padding-block: 1vh;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}
.all-one-service__main__header {
  align-items: center;
  width: 79.69%;
  display: flex;
  justify-content: space-between;
}
.all-one-service__main__header p:nth-child(1) {
  font-weight: bold;
  font-size: 2.5rem;
  color: #000000;
  letter-spacing: -0.3px;
}
.all-one-service__main__header p:nth-child(2) {
  line-height: 1.75rem;
  font-weight: bold;
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 0.3px;
  cursor: pointer;
  border-bottom: 2px solid #000000;
}
.all-one-service__main__hero {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.all-one-service__main__hero__card {
  height: 100%;
  width: 23.1%;
  display: flex;
  flex-direction: column;
  gap: 1.1vh;
}
.all-one-service__main__hero__card__img {
  width: 100%;
  height: 57.5%;
  background-color: rgba(0, 0, 0, 0.1450980392);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}
.all-one-service__main__hero__card__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
}
.all-one-service__main__hero__card__img__footer {
  transition: 800ms;
  width: 100%;
  height: 7vh;
  bottom: 0;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.711);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
}
.all-one-service__main__hero__card__img__footer p {
  color: #000000;
  font-size: 1rem;
}
.all-one-service__main__hero__card i {
  margin-top: 7%;
  font-family: "ProductSans-Thin";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.3px;
}
.all-one-service__main__hero__card p:nth-child(3) {
  font-weight: bold;
  font-size: 1.375rem;
  color: #000000;
  letter-spacing: 0.4px;
}
.all-one-service__main__hero__card p:nth-child(4) {
  color: rgba(0, 0, 0, 0.589);
  font-size: 1rem;
}

@media screen and (max-width: 1080px) {
  .all-one-service {
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "ProductSans-Light";
    gap: 5vh;
    padding-top: 2vh;
  }
  .all-one-service__main::-webkit-scrollbar {
    display: none;
  }
  .all-one-service__main {
    width: 90%;
    height: 100%;
    padding-block: 1vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
  }
  .all-one-service__main__header {
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .all-one-service__main__header p:nth-child(1) {
    font-weight: bold;
    font-size: 1.5rem;
    color: #000000;
    letter-spacing: -0.3px;
    position: relative;
    left: 5%;
  }
  .all-one-service__main__header p:nth-child(2) {
    display: none;
  }
  .all-one-service__main__hero {
    width: 270vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .all-one-service__main__hero__card {
    scroll-snap-align: start;
    height: 100%;
    width: 23.1%;
    display: flex;
    flex-direction: column;
    gap: 1.1vh;
  }
  .all-one-service__main__hero__card__img {
    width: 100%;
    height: 35vh;
    background-color: rgba(0, 0, 0, 0.1450980392);
    border-radius: 20px;
    position: relative;
    cursor: pointer;
  }
  .all-one-service__main__hero__card__img img {
    width: 100%;
    height: 35vh;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 20px;
  }
  .all-one-service__main__hero__card__img__footer {
    transition: 800ms;
    width: 100%;
    height: 7vh;
    bottom: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.711);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3%;
  }
  .all-one-service__main__hero__card__img__footer p {
    color: #000000;
    font-size: 1rem;
  }
  .all-one-service__main__hero__card i {
    margin-top: 7%;
    font-family: "ProductSans-Thin";
    font-size: 1rem;
    color: #000000;
    letter-spacing: 0.3px;
  }
  .all-one-service__main__hero__card p:nth-child(3) {
    font-weight: bold;
    font-size: 1.375rem;
    color: #000000;
    letter-spacing: 0.4px;
  }
  .all-one-service__main__hero__card p:nth-child(4) {
    color: rgba(20, 43, 111, 0.592);
    font-size: 1rem;
  }
}/*# sourceMappingURL=allOneService.css.map */