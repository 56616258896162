.pay-succes {
  height: 100vh;
  width: 100vw;
  background-color: #142B6F;
  font-family: "ProductSans-Light";
  letter-spacing: 0.5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5vh;
  gap: 14vh;
}
.pay-succes__text {
  color: white !important;
  font-size: 13px;
}
.pay-succes__text2 {
  color: white !important;
  font-weight: bold;
  font-size: 42px;
}
.pay-succes__text3 {
  color: white !important;
  font-size: 18px;
}
.pay-succes__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-radius: 60px 60px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
.pay-succes__bottom__animation {
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 8vh;
}
.pay-succes__bottom__text {
  position: relative;
  bottom: 18vh;
  font-size: 20px;
  color: #142B6F;
  text-align: center;
}
.pay-succes__bottom__text2 {
  position: relative;
  bottom: 17vh;
  font-size: 13px;
  color: rgba(20, 43, 111, 0.4784313725);
  text-align: center;
  width: 60%;
}/*# sourceMappingURL=paySucces.css.map */