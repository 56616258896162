.primary-button{
    width: 206px;
    height: 38.8px;
    display: flex;
    border-radius: 25px;
    background-color: #ff4949;
    color: white;
    font-family: "ProductSans-Light";
    text-decoration: none;
    align-items: center;
    justify-content: space-evenly;
    z-index: 999;


    &__text{
        font-size: 15px;
        font-weight: 500;
        z-index: 999;
    }

    &__line{
        font-size: 20px;
    }

    &__arrow{
        font-size: 18px;
    }
    
}