

.bi-rads-dropdown{
    background-color: white;
    padding-top: 3px;
    padding-inline: 20px;
    padding-bottom: 20px;
    gap: 10px;
    z-index: 10000 !important;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-self: center;
   
    position: absolute;
    align-self: center;
    width: auto;
    height: auto;
    color: #FFFFFF !important;
    border-radius: 30px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    animation: biRadsAnim 700ms forwards;


}


@keyframes biRadsAnim{
    0%{
        transform: translateY(5%);
    }
    100%{
        transform: translateY(0);
    }

    
}






@media only screen and (max-width:1080px) { 


    .bi-rads-dropdown{
        background-color: white;
        padding-top: 3px;
        padding-inline: 20px;
        
        gap: 10px;
        z-index: 10000 !important;
        display: flex;
        flex-direction: column;
        cursor: pointer;
       
        position: absolute;
       
        width: auto ;
        height: auto;
        border-radius: 30px;
        box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
        animation: biRadsAnim 700ms forwards;
    
    
    }
    


}

    
   