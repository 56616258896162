.tabs-menu{
    width: 100vw;
    height: 88vh;
    padding-top: 5vh;
    padding-inline: 2vw;
    background-color: black;
    position: absolute;
  

    &__item{
       
        bottom: 0;
        background-color: #000000;
        font-family: "ProductSans-Light";
        position: relative;
        border-radius: 24px;
        width: 98%;
        padding-block: 15px;
        height: auto;
        left: 1%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        z-index: 9999;
         font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

       

        &__text{
            color: #000000 !important;
            transition: all 0.3s ease-in-out;
      
        }

        p {
            border-radius: 16px;
            height: 80%;
            transition: all 0.3s ease-in-out;
            letter-spacing: 0.4px;
            cursor: pointer;
            z-index: 2;
            color: #ffffff82;
            align-items: center;
            align-self: center;
            display: flex;
            padding-inline: 3.2%;
        }

        &__selector{
            justify-self: flex-start;
            left: 1%;
            background-color: rgb(255, 255, 255);
            position: absolute;
            border-radius: 20px;
            width: 27%;
            height: 80%;
            z-index: -1;
            transition: 370ms ease-in-out;
        }

        &__selector--2{
            justify-self: flex-start;
            left: 28%;
            background-color: white;
            position: absolute;
            border-radius: 20px;
            width: 25%;
            height: 80%;
            z-index: 1;
            transition: 370ms ease-in-out;
        }

        &__selector--3{
            justify-self: flex-start;
            left: 53%;
            background-color: white;

            position: absolute;
            border-radius: 20px;
            width: 25.8%;
            height: 80%;
            z-index: 1;
            transition: 370ms ease-in-out;
        }

        &__selector--4{
            justify-self: flex-start;
            left: 82%;
            background-color: white;
            position: absolute;
            border-radius: 20px;
            width: 16.3%;
            height: 80%;
            z-index: 1;
            transition: 370ms ease-in-out;
        }
      


    }

    &__item2{
       
        bottom: 0;
        background-color: #fff;
        border: 1px solid #000000;
        font-family: "ProductSans-Light";
        position: relative;
        border-radius: 24px;
        width: 98%;
        padding-block: 12px;
        height: auto;
        left: 1%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        z-index: 9999;
         font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        overflow: hidden;

        &__selector--6{
            justify-self: flex-start;
            left: 82%;
            background-color: white;
            position: absolute;
            border-radius: 20px;
            width: 16.3%;
            height: 80%;
            z-index: 1;
            transition: 370ms ease-in-out;
        }

  

    }

}

// some css for the iphone 11 iphone 12 and iphone 12 pro max

@media only screen and (min-width: 414px) {
    .tabs-menu{
        &__item{
            font-size: 1rem;
           height: 7vh;
        }
      
    }
}