


.birads-full .main {
   
    margin-top: 0vh;
    height: auto;
    width: 80%;
    padding-top: 3vh;
   

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3vh;
}


.main {
    margin-top: 2vh;
    height: auto;
    width: 80%;
    padding-top: 3vh;
   
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1vh;
}

.doctor-main{
    position: relative;
    width: 86%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

}



.radiologist-footer{
    color: #000000b9;
    margin-top: -5px;
    padding-block: 0px;
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.divider {
    color: rgba(27, 19, 19, 0.096);
    width: 350px;
    padding-top: 18px;
    margin-left: 260px;
}



.text0 {
    display: none;
}

.text1 {
  
    font-family: "ProductSans-Light";
    letter-spacing: 0.5px;
     position: relative;
   font-size: 18px;
   color: rgba(0, 0, 0, 0.701);

 
}

.text2 {
    font-family: "ProductSans-Light";
    position: relative;
     width: 80%;
    color: black;
    font-size: 22px;

}

.text3 {
    display: none;
}




    



.divider2 {
    color: rgba(27, 19, 19, 0.096);
    width: 350px;
    padding-top: 18px;
    margin-left: 260px;
}

.textBottom {
    
    font-family: "ProductSans-Light";
    font-size: 18px;
  
    font-weight: 400;
}


/* Get Started Buttons */

.button31 {
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;
    margin-left: 590px;
    display: flex;
    background-color: rgb(8, 8, 141);
    padding: 4px;
    padding-left: 20px;
    color: white;
    width: 230px;
    height: 42px;
    border-radius: 25px;
    text-decoration: none;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.button31:hover {
    background-color: rgb(255, 208, 0);
    color: rgb(8, 8, 141);
}

.buttonText31 {
    font-family: "ProductSans-Light";
  
    font-size: 16px;
    font-weight: 500;
   
}

.line31 {
  
    font-size: 22px;
    width: 3px;
    
}

.arrow31 {
 
    

    font-size: 15.5px;
}

.doctor-section { 
 
    position: relative;
    align-items: center;
    display: flex;
    gap: 1.4vw;
    height: auto;
    scroll-padding: 1rem;
    padding: 0rem;
    width: 100%;
    border-radius: 5px;
    background-color: white;
  }
  
  .doctor-section__item {
    
    border-right: 2px solid rgba(145, 139, 139, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 450px;
   
    background-color: white;
  }
  
  /* Other styles */

  
  .doctor-img-mobile{
    

border-radius: 2px;
width: 100%;
height: auto;
object-fit: cover;
  }

  .mob-docName {
    font-family: "ProductSans-Light";
    margin-top: 12px;
    margin-right: -5px;
    font-size: 18px;
    line-height: 36px;
    color: black;
    width: 100%;
    font-weight: 700;
    align-self: flex-start;
    letter-spacing: 0.8px;
}

.mob-schName {
    font-family: "ProductSans-Light";
    align-self: flex-start;
    font-size: 16px;
    max-width: 90vw;
    color: rgba(0, 0, 0, 0.839);
    font-weight: 400;
    margin-top: 7px;
    width: 70%;
    line-height: 21px;
}

.mob-exp {
    align-self: flex-start;
    font-family: "ProductSans-Light";
    font-size: 14px;
    color: rgba(0, 0, 0, 0.513);
    max-width: 250px;
    font-weight: 400;
    margin-top: 4px;
}





/*  Radiology Team Page  */

.radiology-team .main {
    margin-top: 5vh;
    height: auto;
    width: 80vw;
    padding-top: 3vh;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3vh;
}

.radiology-team .doctor-main{
    
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    overflow: hidden;
   

}


.radiology-team .doctor-section {
   
    position: relative;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    scroll-padding: 1rem;
    padding: 0rem;
    width: 100%;
    
    border-radius: 5px;
    background-color: white;
  }
  
  .doctor-section__item {
    
     margin-top: 3vh;
    border-right: 2px solid rgba(145, 139, 139, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 17vw;
    height: 490px;
   
    background-color: white;
  }








/*  MOBİLE VERSİON  */

@media only screen and (max-width:1080px) {

  

    .birads-full .main {
   
        margin-top: 0vh;
        height: auto;
        width: 100vw;
        padding-top: 3vh;
       
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 3vh;
    }
    

    

    .doctor-main{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 6vh;
        overflow: hidden;
     
    
    }


    .divider,
    .divider2,
    .buton,
    .buton2,
    .buton3 {
        display: none;
    }
 
    .main {
        margin-top: -2vh;
        margin-left: 0px;
        margin-right: 0px;
        
        width: 100%;
    }
    
    /*  World class radiologists.
         Centered around you. */

    .text1 {
        display: none;
    }
    .text2 {
        display: none;
    }
    /* Swipe Right (ONLY MOBİLE) */
    .text0 {
        line-height: 27px;
        letter-spacing: 0px;
        font-family: "ProductSans-Light";
        display: block;
        font-size: 23px;
        text-align: start;
        margin-left: 3vw;
        margin-top: 16px;
    color: #000000b9;

       
    }
    .text3 {
        font-family: "ProductSans-Light";
        display: block;
        font-size: 15px;
        margin-top: 10px;
        color: #000000b9;


    }

  
    .row {
        margin-top: 7px;
        display: flex;
        overflow-x: auto;
        padding-left: 520px;
        margin-left: 8px;
    }
    .container {
        min-width: 240px;
        margin-left: 35px;
      
    }
    .container img {
        height: 248px;
        width: 228px;
    }
    .row .container:focus,
    .row .container:hover {
        transform: none;
    }
    /* Doctor Name */
    .docName {
        color: #000;
        margin-left: 5px;
        font-size: 33px;
        max-width: 190px;
    }
    /* School Name */
    .schName {
        color: #000000b9;
        margin-left: 5px;
        font-size: 17px;
        max-width: 185px;
    }
    /* Experience*/
    .exp {
        color: #000000b9;
        margin-top: 10px;
        margin-left: 5px;
        max-width: 200px;
    }
    .textBottom {
       
        
        font-family: "ProductSans-Light";
        font-size: 14.4px;
        text-align: center;
        color: #000000b9;
       
        
      
    }
    /* Get Started Button*/
    .button31 {
        
        margin-top: 0px;
        margin-left: 25px;
    }

    .radiologist-footer{
        z-index: 0;
        margin-top: 0px;
        padding-block: 0px;
        width: 100%;
        gap: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
   

    .doctor-section {
        left: 0%;
        position: relative;
        display: flex;
        gap: 15px;
        
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scroll-padding: 1rem;
        padding-right: 3rem;
        margin: 1rem auto 0;
        border-radius: 5px;
        margin-left: 10px;
        background-color: white
      }

      .doctor-section::-webkit-scrollbar{
        display: none;
      }
      
      .doctor-section__item::-webkit-scrollbar{
        display: none;
      }
      .doctor-section__item {
        scrollbar-width: none; 

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 200px;
        scroll-snap-align: start;
        height: 380px;
        scroll-snap-align: start;
        background-color: rgb(250, 250, 250)

        
      }
   
      /* Other styles */
    
      
      .doctor-img-mobile{
    
   
    align-self: flex-start;
     object-fit: cover;
     background-position: center;
    background-size: cover;
    width: 100%;
    height: 220px;
 }


      
  .mob-docName {
    font-family: "ProductSans-Light";
    margin-top: 12px;
    margin-right: -5px;
    font-size: 18px;
    line-height: 22px;
    color: #000000b9;
    width: 100%;
    font-weight: 700;
    align-self: flex-start;
    letter-spacing: 0.8px;
}

.mob-schName {
    font-family: "ProductSans-Light";
    align-self: flex-start;
    font-size: 16px;
    width: 100%;
    color: #000000b9;
    font-weight: 400;
    margin-top: 7px;
    line-height: 21px;
}

.mob-exp {
    align-self: flex-start;
    font-family: "ProductSans-Light";
    font-size: 13px;
    color: #0000008d;
    max-width: 250px;
    font-weight: 400;
    margin-top: 1px;
}
      

  
}