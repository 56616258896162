
.birads-full{
    overflow: hidden;
    transition: 800ms;
}
//BİRADS 1 2 3 
.birads{
    display: flex;
    gap: 5vw;
    padding-top: 13vh;

    
}
.bi-rads{
    
    padding-left: 13vw;
    display: flex;
    flex-direction: column;
    width: 58%;
    height: 100%;
    gap: 15px;
    

    &__right{
        position: relative;
        width: 27vw;
        position: sticky;
        top: 5vh;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 2vh;
        

        &__header{
            
            margin-top: 10vh;
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4vw;
            position: relative;
            

            &__left{
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                gap: 0.3vh;
                font-family: "ProductSans-Light" !important;
            
             p:nth-child(1) {
                    color: black;
                    font-weight: bold;
                }
             p:nth-child(2) {
                    font-weight:10;
                    color: black;
                }
             p:nth-child(3) {
                    color: rgb(123, 123, 123);
                }
            }

            &__image{
                width: 70px;
                height: 70px;
                border-radius: 14%;
            }
       
       
        }

        &__image-ribbon{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
           
            overflow: hidden;

            img {
                width: 60%;
                height: 136px;
              
               
                
            }
 

        }

        &__main{
            width: 100%;
            gap: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: auto;
           

            &__divider{
                margin-top: 3vh;
                width: 60%;
                height: 1px;
                background-color: rgba(0, 0, 0, 0.086);
                
            }

            &__card{
                width: 80%;
                
                border-radius: 30px;
                background-color:rgb(2, 24, 74);
                font-family: "ProductSans-Light";
                color: white;
                padding: 20px;
                gap: 4vh;
                display: flex;
                flex-direction: column;

                &__header{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 3vh;


                    &__title{
                        font-size: 1rem;
                        font-weight: bold;
    
                    }
    
                    &__area{
                        font-family: "ProductSans-Light" !important;
                        display: flex;
                        flex-direction: column;
                        gap: 0.5vh;
                        font-size: 1rem;
                        font-weight: 100;
    
                       
                    }
    
                    &__divider{
                        width: 40%;
                        height: 1px;
                        background-color: white;
                        
                    }

                }

                &__text{
                    font-size: 1rem;
                    width: 93%;
                    font-family: "ProductSans-Light" !important;
                    font-weight: 100 !important;
                }

               


            }
        }

        &__info{
            display: flex;
            flex-direction: column;
            font-family: "ProductSans-Thin" !important;
            align-items: flex-start;
            justify-content: center;
            width: 80%;
            height: auto;
            gap: 5px;
            margin-top: 12vh;

            &__line{
                width: 100%;
                height: 2px;
                background-color: #000000;
            }
            &__circle{
                width: 15px;
                height: 15px;
                border-radius: 50%;
                border: 3px solid #000000;
                position: relative;
                
            }

            p:nth-child(2){
                margin-top: 10px;
                font-size: "ProductSans-Thin" !important;
                color:rgb(20, 43, 111);
                font-size: 1.25rem;
                font-weight: bold;
                letter-spacing: 0.2px;
                position: relative;
              
            }

            p:nth-child(3){
               
                font-size: "ProductSans-Thin" !important;
                color:rgb(20, 43, 111);
                font-size: 0.9rem;
                width: 98%;
                letter-spacing: 0.5px;
                position: relative;
                
            }
      
      
      
        }


        &__blur{
            
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            filter: blur(6px);
            background-color: #F4F5F7;
        }
    }
   

    &__header{
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 100%;
        border-bottom: 1px solid #00000032;
        padding-bottom: 3vh;

        &__text{
            line-height: 42px !important;
            font-family: "Optima" !important;
            
        }
    }

    &__main{
        margin-top: -4vh;
        border-top: 1px solid rgba(0, 0, 0, 0.238);
        padding-top: 4vh;
        display: flex;
        flex-direction: column;
        gap: 20px;
        
    }
    
   
}


//BİRADS 4


.bi-rads4{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    gap: 15px;
    padding: 15vh 14vw;


    &__header{
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 100%;
        border-bottom: 1px solid rgba(128, 128, 128, 0.215);
        padding-bottom: 7vh;

        &__text{
           color: rgb(66, 66, 66);
           font-family: "ProductSans-Light";
           font-size: 18px;
           line-height: 24px;
           position: relative;
           top: 10px;

           span{
            
            font-weight: bold;
           }
          
        }
   
    }

    &__main{
        display: flex;
        gap: 105px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.215);
        padding-top: 2vh;
        padding-bottom: 4.1vh;


        &__left{
           
            font-family: "ProductSans-Light";
            font-size: 18px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-weight: 400;
            width: 50%;
            line-height: 23.1px;
            
            p:nth-child(1){
               font-size: 25px;
               font-weight: 800;
               letter-spacing: 0px;
            }
       
        }

        &__right{
             width: 40%;
             font-family: "ProductSans-Light" !important;
            font-size: 15px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            
          

            p:nth-child(1){
                font-size: 17px;
                font-weight: 800;
             }
             p:nth-child(6){
                font-size: 17px;
                font-weight: 800;
             }

             &__images{
                width: 94px !important;
                height: 91px !important;
             }

           
        }
    }


    &__background{
        position: absolute;
        top: 82.9px;
        right: 0;
        z-index: -1;
        width: 44vw;
        height: 300vh;
        background-color: rgba(255, 208, 0, 0.203);;
        filter: blur(4px);
        -webkit-filter: blur(4px);
    }
   

}



//MOBİLE

@media screen and (max-width: 1080px) {
.birads {
        display: flex;
        
        
      

    }
.bi-rads{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    gap: 1px;
    padding: 0 0;
    padding-top: 0vh;
    padding-inline: 4vw;
   


    &__right{
        display: none;
    }

    &__header{
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        border-bottom: 1px solid rgba(128, 128, 128, 0.215);
        padding-bottom: 3vh;

        &__text{
            line-height: 32px;
            width: 100%;
        }
        &__text2{
            display: none;
        }
    }

    &__main{
        margin-top: 10px;
        margin-bottom: 0vh;
        display: flex;
        flex-direction: column;
        gap: 20px;
      

        .button{
            display: flex;
          
        }
        
        
    }
    
   
}

.bi-rads__footerRev{
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 64vw;
    background-color: white;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000; ;
    align-items: center;
    z-index: 199;
    justify-content: center;

    animation: bottomToTopRev 0.5s ease-in-out;
    -webkit-animation: bottomToTopRev 0.5s ease-in-out;
    -moz-animation: bottomToTopRev 0.5s ease-in-out;
    -o-animation: bottomToTopRev 0.5s ease-in-out;
    -ms-animation: bottomToTopRev 0.5s ease-in-out;

    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

   
    

    &__button {
         background-color: #000000;
         width: 59vw;
         height: 40px;
         border-radius: 30px;
         font-family: "ProductSans-Light" !important;
         display: flex;
         align-items: center;
         justify-content: center;
         color: white;
         font-size: 15px;
         gap: 15%;

         &:hover{
                background-color: rgb(255, 208, 0);
                color: #000000;
         }
    }

     


    @keyframes  bottomToTop {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    @keyframes  bottomToTopRev {
        0% {
            opacity: 1;
            transform: translateX(0);
        }
        100% {
            opacity: 0;
            transform: translateX(-100%);
        }
    
        
    }
}


    

.bi-rads__footer{
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 64vw;
    background-color: white;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000; ;
    align-items: center;
    z-index: 199;
    justify-content: center;

    animation: bottomToTop 0.5s ease-in-out 0.5s;
    -webkit-animation: bottomToTop 0.5s ease-in-out;
    -moz-animation: bottomToTop 0.5s ease-in-out;
    -o-animation: bottomToTop 0.5s ease-in-out;
    -ms-animation: bottomToTop 0.5s ease-in-out;

    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

   
    

    &__button{
         background-color: #000000;
         width: 59vw;
         height: 40px;
         border-radius: 30px;
         font-family: "ProductSans-Light" !important;
         display: flex;
         align-items: center;
         justify-content: center;
         color: white;
         font-size: 15px;
         gap: 15%;
         

         &:hover{
                background-color: rgb(255, 208, 0);
                color: #000000;
         }
    }

     


    @keyframes  bottomToTop {
        0% {
            opacity: 0;
            transform: translateX(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    
        
    }

    @keyframes  bottomToTopRev {
        0% {
            opacity: 0;
            transform: translateX(0);
        }
        100% {
            opacity: 1;
            transform: translateX(-100%);
        }
    
        
    }





}






//BİRADS 4 MOBİLE


.bi-rads4{
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
    height: 100%;
    gap: 15px;
    padding: 0vh 3.5vw;
    padding-top: 14vh !important;


    &__header{
        display: flex;
        flex-direction: column;
        gap: 35px;
        width: 100%;
        border-bottom: 1px solid rgba(128, 128, 128, 0.215);
        padding-bottom: 7vh;
      
        &__title{
             width: 90% !important;
        }
        &__text{
           color: rgb(66, 66, 66);
           font-family: "ProductSans-Light" !important;
           font-size: 18px;
           line-height: 24px;
           position: relative;
           top: 10px;
           width: 100% !important;

           span{
            font-weight: 800;
           }
          
        }
   
    }

    &__main{
        display: flex;
        flex-direction: column;
        gap: 45px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.215);
        padding-top: 2vh;
        padding-bottom: 4.1vh;


        &__left{
           
            font-family: "ProductSans-Light" !important;
            font-size: 18px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-weight: 400;
            width: 100%;
            line-height: 23.1px;
            
            p:nth-child(1){
               font-size: 25px;
               font-weight: 800;
            }
       
        }

        &__right{
             width: 80%;
             font-family: "ProductSans-Light" !important;
            font-size: 15px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            p:nth-child(1){
                font-size: 17px;
                font-weight: 800;
             }
             p:nth-child(6){
                font-size: 17px;
                font-weight: 800;
             }
             &__imagess{
                gap: 20px !important;
               
                width: 125%;
                align-items: center;
                display: flex;
                

             }
             &__images{
                width: 163px !important;
                height: 163px !important;
             }

           
        }
    }


    &__background{
        position: absolute;
        top: 82.9px;
        right: 0;
        z-index: -1;
        width: 44vw;
        height: 300vh;
        background-color: rgba(255, 208, 0, 0.203);;
        filter: blur(4px);
        -webkit-filter: blur(4px);
    }
   

}







}