.human-error{
    width: 100vw;
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    overflow: hidden;
    
    
}

.human-error-main{
    overflow: hidden;
    height: auto;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; 
    gap: 3vw;
    
   
}


.rhap_main-controls{
    position: relative;
    right: 40px !important;
}

.rhap_volume-container{
    position: relative;
    right: 20px !important;
    width: 20px !important;
}

.rhap_repeat-button{
    display: none !important;
}




.human-error-main-header{
    width: 100%;
    height: auto;
    display: flex;
    
 
   
   
}

.human-error-main-header-left{
    width: 680PX;
    gap: 20px;
    display: inline-flex;
    flex-direction: column;
   
}




.human-error-main-header-left p:nth-child(1) {
    width: fit-content;
   font-size: 15px;
   color: #000000;
   font-family: "ProductSans-Light";
   background-color: #FFD000;
   padding: 11px 28px;
   border-radius: 88px;

   }

.human-error-main-header-left h5 {
   font-size: 46px;
   font-family: "ProductSans-Light";
   color: #01040f;
   font-weight: 500;
   letter-spacing: 0px;
   line-height: 49px;
   }

   .human-error-main-header-left h2 {
    border-bottom: 6px solid #FFD000;
    font-weight: 500;
    font-size: 46px;
    line-height: 40px;
    width: auto;
    display: inline-block;
   
   }

   .human-error-main-header-left p:nth-child(3) {
    font-size: 16px;
    font-family: "ProductSans-Light";
    color: #949494;
    margin-top: 10px;
    
}

.human-error-main-header-right{
    width: 48%;
   height: 100%;
   position: relative;
   bottom: 30px;
  
}

.human-error-main-header-left-audio{
    width: 480px;
    height: 50px;
    cursor: pointer;
}


.human-error-main-hero{
    border-top: 1px solid #142b6f31;
    gap: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    
}

.human-error-main-hero-texts{
    margin-top: 40px;
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 19px;
}

.human-error-main-hero-texts:nth-child(5) {

    height: 800px;
}

.human-error-main-hero-texts ul {
    display: flex;
    flex-direction: column;
    gap: 21px;
    
    margin-left: 4%;
}

.human-error-main-hero-texts h2{
 text-align: start;
font-family: "ProductSans-Light";
font-size: 24px;
color: #01030b;
font-weight: bold;
letter-spacing: 0.2px;

}

.human-error-main-hero-texts p {
    text-align: start;
    font-size: 17px;
    font-family: "ProductSans-Light";
        color: #142b6fd6;
    letter-spacing: 0.3px;
   
}
.dropdown-pages-text{
    text-align: start;
    font-size: 18px;
    font-family: "ProductSans-Light";
    color: #142b6f;
}


.dropdown-pages-text span{
    text-align: start;
    font-size: 18px;
    font-family: "ProductSans-Light";
    color: #142b6f;
   font-weight: 800;
}



.human-error-main-hero-texts span{
    text-align: start;
    font-size: 18px;
    font-family: "ProductSans-Light";
    color: #142b6f;
   font-weight: 800;
}


.human-error-pie{
    display: flex;
    width: 490px;
    align-self: center;
}

.human-error-child{

    display: flex;
    gap: 55px;
}

.human-error .footer{
    position: relative;
    right: 46%;
}


.upside-footer{
    margin-left: 0vw;
    padding-left: 0vw;
    margin-top: 20px;
    border-top: 1px solid #142b6f53;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    font-family: "ProductSans-Light";
    font-size: 18px;
    width: 72%;
}
 
.button-human {
    cursor: pointer;
    width: 178px;
    height: 40.8px;
    display: flex;
    border-radius: 25px;
    border: 1px solid #142b6f;
    color: #142b6f;;
    font-family: "ProductSans-Light";
    text-decoration: none;
    align-items: center;
    justify-content: space-evenly;

}




.buttonText-human {

    font-size: 17px;
    font-weight: 500;
}

.line-human {
   
    font-size: 20px;
   
}

.arrow-human {
    font-size: 18px;
}

.human-error .button{
    cursor: pointer;
    width: 230px;
    height: 48.8px;
}


.accordions-text{
    font-family: "ProductSans-Light";
    font-weight: 400;
    color: #5d9695e9;
    font-size: 15px;
    line-height: 23.399999618530273px;
}

.accordions-text span{
    font-family: "ProductSans-Light";
    font-weight: 800;
    font-size: 18.4px;
    letter-spacing: 0px;
   
}

.ml-20{
    margin-left: 25px; 
}

.mui-widthh{
    width: 80%;
}

.accordions-yellow-button{
    border-radius: 20px;
    width: fit-content !important;
    padding: 9px 28px !important;

    align-items: center;
    justify-content: center;
    display: flex;
    color: #142b6f;
    font-family: "ProductSans-Light";
    font-size: 15px;
   
   
}

.normal-text{

    letter-spacing: 0.4px;
    font-family: "ProductSans-Light";
    font-weight: normal;
    color: rgba(20, 43, 111, 0.908);
    font-size: 17px;
    line-height: 23.399999618530273px;
    display: flex;
    gap: 11px;
    flex-direction: column;
    width: 95%;
}

.normal-text span{
    font-family: "ProductSans-Light";
    font-weight: bold;
    color: rgb(20, 43, 111);
    font-size: 18.4px;
    letter-spacing: 0.4px;
    cursor: pointer;
    position: relative;
}

.normal-text span::after{
    content: "";
    position: absolute;
    background-color: rgb(249, 208, 24);
    height: 2px;
    width: 0;
    left: -1px;
    bottom: -2px;
    transition: 0.3s;
}

.normal-text span:hover::after{
    width: 100%;
}


.big-normal-text{
    font-family: "ProductSans-Light";
    font-weight: bold;
    color: rgb(20, 43, 111);
    font-size: 24px;
    line-height: 31px;
    display: flex;
    gap: 11px;
    flex-direction: column;
    letter-spacing: 0px;
    
   
}

.normal-text span{
    font-family: "ProductSans-Light";
    font-weight: bold;
    color: rgb(20, 43, 111);
    font-size: 18.4px;
    letter-spacing: 0.4px;
}




@media screen and (max-width: 1080px) {
  

    .human-error{

        overflow: hidden;
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-top: 12vh ;
        
        height: auto;
        
    }
    .human-error-main{
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .human-error-main-header{
         width: 100vw;
         overflow: hidden;
    }

    .upside-footer{
        margin-left: 0;
        padding-left: 0;
        margin-top: 20px;
        border-top: 1px solid rgba(34, 32, 32, 0.231);
        gap: 1rem;
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        font-family: "ProductSans-Light";
        font-size: 18px;
        width: 85%;
    }

    .human-error-main-header-right{
        position: absolute;
        top: 25vh;
        left: 95vw;
        width: 100px;
        height: 90px;
        z-index: -1;
        overflow: hidden;
        transform: scale(5);
    }

    .human-error-main-header-left h5 {
        font-size: 36px !important;
        font-family: "ProductSans-Light";
        color: #0A2470;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 49px;
        width: 70%;
        }
     
        .human-error-main-header-left h2 {
         border-bottom: 6px solid #FFD000;
         font-weight: 500;
         font-size: 36px !important;
         line-height: 40px;
         display: inline-block;
        }
        
    .normal-text{
        font-family: "ProductSans-Light";
        font-weight: 400;
        color: rgba(20, 43, 111, 0.908);
        font-size: 100%;
        line-height: 23px;
        display: flex;
        gap: 11px;
        flex-direction: column;
        width: 95%;
        
    }
    .human-error-main-hero{
        gap: 4rem;
        width: 96%;
        display: flex;
        flex-direction: column;
        text-align: start;
        
    }


    .human-error-pie{
        display: flex;
        width: 250px;
        align-self: center;
    }

   
    
    


.human-error-main-header-left p:nth-child(3) {
    font-size: 17px;
    font-family: "ProductSans-Light";
    color: #949494;
    margin-top: 10px;
    line-height: 22px;
    
}
    
   

    .mui-widthh{
        width: 95%;
    }
    
}