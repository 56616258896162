.mobile-footer{
    top: 25px;
    position: relative;
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: center;
    color: black;
    padding-bottom: 12vh;
}

.mobile-footer-main {
    width: 89%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;


}
.dividero-footer{
    width: 346px;
    height: 1px;
    background-color: rgba(163, 163, 163, 0.384);

}
.mobile-footer-image{
    margin-top: 37px;
    width: 140px;
    height: 43px;
}
    
.mobile-footer-accordion-list{
    top: -6px;
    position: relative;
    list-style: none;
    font-family: "ProductSans-Light";
    gap: 9px;
    display: flex;
    flex-direction: column;
}

.mobile-footer-accordion-list li {
    color: black;
}

.mobile-footer-icons{
    width: 120px;
}
.mobile-footer-text{
    letter-spacing: 0.2px;
   margin-top: 10px;
    height: 43px;
    font-family: "ProductSans-Light";
    font-size: 15px;
    font-weight: 500;
    color: #5B5B5B;
}
.mobile-footer-text2{
    letter-spacing: 0.2px;
     margin-top: 30px;
     line-height: 21px;
    height: 43px;
    font-family: "ProductSans-Light";
    font-size: 16.5px;
    font-weight: 100;
    color: #8C8C8C;
}

.mobile-footer-pay{
    margin-top: 80px;
   
    width: 160px;
}