.password-reset{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    padding: 10VH 20px;
    gap: 30px;
    font-family: "ProductSans-Light";
}


.password-reset__main{
   
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    width: fit-content;
    height: fit-content;
    background-color: white;
            border-radius: 13px;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.password-reset__main__title{
  font-size: 22px;
}


@media screen and (max-width: 1080px){

  .password-reset{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    padding: 10VH 20px;
    gap: 30px;
    font-family: "ProductSans-Light";
}


.password-reset__main{
   
    display: flex;
    flex-direction: column;
    gap: 30px;
     justify-content: flex-start;
     align-items: flex-start;
    padding-block: 8vh;

    width:  100%;
    height: 40;
    background-color: white;
            border-radius: 13px;
            box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.password-reset__main__title{
  font-size: 22px;
  width: 400px;
}

  
}