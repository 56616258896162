.pay-screen {
  /*background-color: #F5F7FA;*/

  border: none;
  position: relative;
  height: auto;
  width: fit-content;
  overflow: hidden;
  display: flex;
  padding-top: 3vh;
  align-self: center;
  position: relative;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -0%);
 
  
}

.pay-screen-main {
  position: relative;
  
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  width: fit-content;
}

.pay-screen-button {
  top: 0px;
  left: 18px;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-radius: 38px;
  width: 90px;
  height: 30px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
  font-family: "ProductSans-Light";
  color: var(--navyBlue);
}

.pay-screen-button:hover {
  box-shadow: 0 3.4px 8px -2px rgb(0 0 0 / 60%);
}

#payment-form {

  gap: 15px;
  position: relative;
  bottom: 5%;
  right: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  min-width: 500px;
  align-self: center;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
  border-radius: 20px;
  padding: 40px;
  height: auto;
 
}

.payment-form-head-text {
  font-family: "ProductSans-Light";
  color: #333333;
  font-size: 21px;
  align-self: flex-start;
}


#payment-form .nameİnput {
  position: relative;
  bottom: 5px;
  margin-top: -5px;
  width: 100%;
  align-self: flex-start;
  background-color: #F5F7FA;
}


#payment-element {
  padding-bottom: 2vh;
  width: 100%;
 
  padding: 3vh;
  /* border-bottom: 1px solid rgba(185, 12, 12, 0.189);*/
  /*background-color: #dbd3d307; */
  border-radius: 15px;
}

.price-exp {
  margin-top: -2%;
  width: 95%;
  padding: 17px;
  height: auto;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-right: 2%;
  background-color: #413f3f07 !important;
  border-radius: 15px;

}

.price-exp-tax-more {
  
  height: 35px;
  cursor: pointer;
  border: none !important;
  border-bottom: none;
  width: 100% !important;
  border-radius: 16px;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  font-size: 17px !important;
}

.price-exp-tax-more:hover {
  box-shadow: 0 4px 15px -5px rgba(20, 43, 111, 0.2);
  border-color: rgba(20, 43, 111, 0.2);
  transition: 500ms;

}



.price-exp-tax-more p {
  
  font-size: 16px;
  color: var(--navyBlue);
  font-family: "ProductSans-Light";


}

.price-exp div {
  display: flex;
  justify-content: space-between;
 font-family: "ProductSans-Light";
 letter-spacing: 0.2PX;
  font-size: 14px;
  color: black;
  line-height: 30px;
}

.price-exp div:nth-child(1) {
  border-bottom: 1px solid rgba(93, 90, 90, 0.216);
}




.price-exp div:nth-child(4) {
  font-weight: 700;

}

/* Buttons and links */
.pay-screen button {
  margin-top: 5px;
  display: flex;
  align-self: center;
  background: var(--navyBlue);
  width: 220px;
  height: 50px;
  font-family: "ProductSans-Light";
  letter-spacing: 0.7px;
  color: #ffffff;
  border-radius: 25px;
  border: 0;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 200;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.pay-screen button:hover {
  filter: contrast(115%);
}

.pay-screen button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.stripe-img {

  top: 2px;
  position: relative;
  width: 26%;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment-testoq {
  cursor: pointer;
  align-self: center;
  background-color: black;
  padding-inline: 20vw;
  padding-block: 3vh;
  border-radius: 44px;
  color: rgb(230, 230, 30);
}


@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

























@media only screen and (max-width: 1080px) {

  .pay-screen {
    margin-left: 0vw;
    /*background-color: #F5F7FA;*/
    background-color: white !important;
    border: none;
    position: relative;
    height: 80%;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1vh;
   
   
   
    
  }
  
  .pay-screen-main {
    
    padding-top: 40px !important;
    border-radius: 30px;
    padding: 20px;
    position: relative;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: auto;
    width: 95vw;
  }
  

  #payment-form {
    background-color: none;
    min-width: 110%;
    gap: 38px;
    position: relative;
    bottom: 10%;
    right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10vw !important;
   
    align-self: center;
    border-radius: 7px;
    padding: 0px;
    
   
  }
  
  .pay-screen-button {
    top: 0px;
    left: 18px;
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 38px;
    width: 90px;
    height: 30px;
    box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
    font-family: "ProductSans-Light";
    color: var(--navyBlue);
  }
  
  .pay-screen-button:hover {
    box-shadow: 0 3.4px 8px -2px rgb(0 0 0 / 60%);
  }
  

  .payment-form-head-text {
    font-family: "ProductSans-Light";
    color: white !important;
    font-size: 21px;
    align-self: flex-start;
  }
  
  
  #payment-element {
    
    width: 93%;
    font-size: 25px;
    color: white !important;
    /* border-bottom: 1px solid rgba(185, 12, 12, 0.189);*/
    padding: 0vh;
    background-color: transparent;
    
  }
  
  .price-exp {
    margin-top: 1%;
    width: 95%;
    padding: 17px;
    height: auto;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-right: 2%;
    background-color: #dbd3d307;
    border-radius: 15px;
  }
  
  .price-exp-tax-more {
    height: 35px;
    cursor: pointer;
    border: none !important;
    border-bottom: none;
    width: 100% !important;
    border-radius: 16px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-size: 17px !important;
  }
  
  .price-exp-tax-more:hover {
    box-shadow: 0 4px 15px -5px rgba(20, 43, 111, 0.2);
    border-color: rgba(20, 43, 111, 0.2);
    transition: 500ms;
  
  }
  
  
  
  .price-exp-tax-more p {
    font-size: 16px;
    color: var(--navyBlue);
    font-family: "ProductSans-Light";
  
  
  }
  
  .price-exp div {
    display: flex;
    justify-content: space-between;
  
    font-size: 14px;
    color: black;
    line-height: 30px;
  }
  
  .price-exp div:nth-child(1) {
    border-bottom: 1px solid rgba(93, 90, 90, 0.216);
  }
  
  
  
  
  .price-exp div:nth-child(4) {
    font-weight: 700;
  
  }
  
  /* Buttons and links */
  .pay-screen button {
    margin-top: 5px;
    display: flex;
    align-self: center;
    background: var(--navyBlue);
    width: 220px;
    height: 50px;
    font-family: "ProductSans-Light";
    letter-spacing: 0.7px;
    color: #ffffff;
    border-radius: 25px;
    border: 0;
    padding: 12px 16px;
    font-size: 18px;
    font-weight: 200;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  
  }
  
  .pay-screen button:hover {
    filter: contrast(115%);
  }
  
  .pay-screen button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  .stripe-img {
  
    top: 10px;
    position: relative;
    width: 26%;
  }
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  .payment-testoq {
    cursor: pointer;
    align-self: center;
    background-color: black;
    padding-inline: 20vw;
    padding-block: 3vh;
    border-radius: 44px;
    color: rgb(230, 230, 30);
  }
  
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  
 
}