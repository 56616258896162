
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @screen iphone7 {
    .mt-28-iphone7 {
      @apply mt-28;
    }
  }
}

progress[value]::-webkit-progress-value {
  background-color: rgb(34 197 94); /* Örnek bir arka plan rengi */
  border-radius: 8px;     /* Örnek bir kenar yarıçapı */
  height: 8px;
  transition: width 0.3s ease; /* Genişlik değişikliklerinde geçiş efekti */
  /* Daha fazla stil özelliği ekleyebilirsiniz */
}
progress[value]::-webkit-progress-bar {
  width: 100%;
  background: rgba(128, 128, 128, 0.105);
  height: 8px;
  border-radius: 8px;
}

.stroke-current-new {
  stroke: #5D9695;
}
.stroke-current-new-red {
  stroke: #9d100d;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
 
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  display : none !important;
  }
  
  
  
  .css-w66kx-MuiChip-root{
    background-color: #000000 !important;
    color: #fff !important;
  }
  

.button-2 {
  cursor: pointer;
  top: 93%;
  left: 17%;
  width: 230px;
  height: 47px;
  position: absolute;
  border-radius: 25px;
  justify-content: space-evenly;
  align-items: center;
  text-decoration: none;
  display: flex;
  background-color: #000000;
  color: white;
    font-family: "ProductSans-Light";

}
 

.button-2:hover {
  background-color: rgb(255, 208, 0);
  color: #000000;
}

.buttonText-2 {
 margin: 13px 30px;
  font-size: 16px;
  font-weight: 500;
}

/* Pop Up Settings Start
 */
 :root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
  
  --form-xl: 21px;
  --form-lg: 19px;
  --form-md: 17px;
  --form-sm: 15px;

  --doc-height: 100%;

  --blue: #000000bf;
  --blue-trans: rgba(20, 43, 111, 0.52);

  --font-light: "ProductSans-Light";
  --font-thin: "ProductSans-Thin";
}

[data-rsbs-overlay] {
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  display: flex;
  background: var(--rsbs-bg);
  flex-direction: column;
  height:  81vh;
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
}
[data-rsbs-overlay]:focus {
  outline: none;
}
[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}

.summary-popup [data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: 100vw;
  margin-left: 0vw;
}


[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: 46vw;
  margin-left: 26vw;
 
}



[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}
[data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: "";
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}
[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}
[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: 1;
  padding-top: 2px;
  padding-bottom: 8px;
}
[data-rsbs-header]:before {
  position: absolute;
  content: "";
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: var(--rsbs-handle-bg);
}
@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}
[data-rsbs-has-header="false"] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}
[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
[data-rsbs-scroll]:focus {
  outline: none;
}
[data-rsbs-has-footer="false"] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}
[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}
[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
    0 2px 0 var(--rsbs-bg);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}



[data-rsbs-state="closed"],
[data-rsbs-state="closing"] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}







/*

@media screen and (pointer: coarse) {
  @supports ( (overscroll-behavior-y: none))  {

    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
   
  }
}

@media screen and (pointer: coarse) {
  @supports (not (overscroll-behavior-y: none))  {
    html {
      height: 100%;
      overflow: hidden;
    }
    body {
      margin: 0px;
      max-height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

body{
  overscroll-behavior-y: none;
}

*/





:root{
  --navyBlue: #000000;
}


.style-none{
  text-decoration: none;
}

.flexo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 20px;
  background: rgb(7,101,133);
  background: linear-gradient(177deg, rgba(7,101,133,1) 48%, rgba(11,103,135,1) 64%, rgba(255,255,255,1) 100%);
}




/* PRODUCT SANS */
@font-face {
  font-family: "ProductSans-Thin";
  src: url(../fonts/ProductSans/ProductSans-Light.woff) format("woff");
}

@font-face {
  font-family: "ProductSans-Thin";
  src: url(../fonts/ProductSans/ProductSans-Regular.woff) format("woff");
  font-weight: bold;
}



@font-face {
  font-family: "ProductSans-Light";
  src: url(../fonts/ProductSans/ProductSans-Regular.woff) format("woff");
}

 @font-face {
  font-family: "ProductSans-Light";
  src: url(../fonts/ProductSans/ProductSans-Medium.woff) format("woff");
  font-weight: bold;
}
 




/* OPTİMA */

@font-face {
  font-family: "Optima";
  src: url(../fonts/Optima/AnyConv.com__OPTIMA.woff) format("woff")
}

@font-face {
  font-family: "Optima";
  src: url(../fonts/Optima/AnyConv.com__OPTIMA_B.woff) format("woff");
  font-weight:bold;
}
@font-face {
  font-family: "Optima Medium";
  src: url(../fonts/Optima/AnyConv.com__Optima\ Medium.woff) format("woff")
}

.human-error {
  scroll-behavior: smooth;
  
}


.VitamuHome{
  gap: 7vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
  padding-bottom: 5vh;
 
 }


.App {
 
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
   height: calc(var(--vh, 1vh) * 100);  
  
}

.App-new {
 
  overflow: hidden;
  position: relative;
  width: 100vw;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */

  
}



.left-slide{
 
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: transform 750ms cubic-bezier(.73,.19,0,1.02);
 
}

/* .box,.box2,.box3,.box4 {

  border: none;
  border-top: 7px solid rgb(8, 8, 141);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}  */

.card1,
.card2,
.card3,
.card4,
.card5,
.card6,
.card7,
.card8,
.card85,
.card9,
.card10,
.card11,
.card12,
.card13,
.card14,
.card15,
.card16,
.card17,
.register-page,
login-page{
    border: none;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.register-page label {
  font-weight: 800;
  font-size: 16px;
}

.register-button{
  cursor: pointer;
  font-size: 15px;
  font-family: Sofia-Medium !important;
}


.Panel{
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}
.Panel h2 {
  
  color: red;
  font-size: 19px;
  text-align: start;
}
.Panel p {
  color: rgba(0, 0, 0, 0.784);
  font-size: 18px;
  text-align: start;
 }

 .panel-item{
  display: flex;
  gap: 15px;
 }


 /* Form items
 ................................................. */


 .slide-background{
  top: 60px;
  height: 650px;
  width: 1300px;
 
  background-color: #F1F3F7;
  z-index: -1;
  border-bottom-right-radius: 450px;
  border-top-right-radius: 450px;
  position: fixed;
 }

 .slide-background2{
  left: -20px;
  top: -20px;
  height: 800px;
  width: 1480px;
  position: fixed;
  background-color: #F8F9FB;
  z-index: -2;
  border-bottom-right-radius: 450px;
  border-top-right-radius: 450px;
 }
 






 

 .primary-text{
  color: #000000;
  font-family: "ProductSans-Light";
  letter-spacing: 0px;
  letter-spacing: 0.3px;
  
 }


 .display-none{
  display: none;
  transition: 800ms;
 }



 .is-error-text{
  font-family: "ProductSans-Light";
  letter-spacing: 0.7px !important;
  font-size: 13px !important;
  margin-top: 10px;
  color: red !important;
  transition: 800ms;
 }

.forward-back-buttons-text{
  position: absolute;
  left: 33%;
  top: 1vh;
  opacity: 0;
  font-family: "ProductSans-Light";
  letter-spacing: 0.7px;
  font-size: 14px;
  
  
}
.forward-back-buttons-text-slide{
  letter-spacing: 0.7px;
  font-size: 14px;
  font-family: "ProductSans-Light";
  position: absolute;
  left: 33%;
  top: 1.2vh;
  opacity: 0;
  animation: textSlide 3s ease-in;
  
}

@keyframes textSlide {
  0% {
    opacity: 0;
    left: 42%;
   
  }
  8% {
    opacity: 1;
    left: 33%;
   
  }
  50% {
    opacity: 1;
    left: 33%;

  }

  92% {
    transform: translateX(0%);
    left: 33%;
    opacity: 1;


   
  }
  100% {
    opacity: 0;
    left: 42%;
  }
  
}


.forward-back-buttons{
  position: relative;
  top: 92vh;

  width: 100%;
  /* make animation slide left*/
  display: flex;
 }

.forward-back-buttons-slide{
  position: relative;
  top: 92vh;
  width: 100%;
  /* make animation slide left*/
  animation: slide_left 3s ease-in;
 }

 @keyframes slide_left {
  0% {
    transform: translateX(0);
  }
  8% {
    transform: translateX(5%);
  }
  50% {
    transform: translateX(5%);
  }

  92% {
    transform: translateX(5%);
  }

  100% {
    transform: translateX(0%);
  }
 

}

















@media screen and (max-width: 1080px) {

  /* Pop Up Settings Start
 */
:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

[data-rsbs-overlay] {
  border-top-left-radius: 26px;
  border-top-right-radius: 26px;
  display: flex;
  background: var(--rsbs-bg);
  flex-direction: column;
  height: var(--rsbs-overlay-h);
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
}
[data-rsbs-overlay]:focus {
  outline: none;
}
[data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}
[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: var(--rsbs-max-w);
  margin-left: var(--rsbs-ml);
  margin-right: var(--rsbs-mr);
}
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}
[data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: "";
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}
[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}
[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: 1;
  padding-top: 2px;
  padding-bottom: 8px;
}
[data-rsbs-header]:before {
  position: absolute;
  content: "";
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: var(--rsbs-handle-bg);
}
@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}
[data-rsbs-has-header="false"] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}
[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
[data-rsbs-scroll]:focus {
  outline: none;
}
[data-rsbs-has-footer="false"] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}
[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}
[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
    0 2px 0 var(--rsbs-bg);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}



[data-rsbs-state="closed"],
[data-rsbs-state="closing"] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}




  
   
 
.VitamuHome{
  gap: 0vh;
  max-width: 100vw;
  overflow: hidden;
  padding-bottom: 2vh;

}

.iconico{
  font-family: "ProductSans-Light";
}




.slide-background{
  align-self: center;
  height: 52vh;
  width:  86vw;
  background-color: #F1F3F7;
  z-index: -1;
  border-bottom-right-radius: 70%;
  border-top-right-radius: 70%;
  position: fixed;
  top: 14%;
 }

 .slide-background2{
  top: 7%;
  margin: 0 auto;
  align-self: center;
  left: 0px;
  height: 64vh;
  width: 99vw;
  position: fixed;
  background-color: #F8F9FB;
  z-index: -2;
  border-bottom-right-radius: 450px;
  border-top-right-radius: 450px;
 }


 .button-2 {
  z-index: 10;
  position: absolute;
  top: 105%;
  left: 6%;
}

.hero-button-hover{
  z-index: 4 ;
  padding-top: 11px;
  padding-left: 23px;
  gap: 17px;

  display: flex;
  flex-direction: column;
  cursor: pointer;
  top: 39%;
  left: 5%;
  position: absolute;
  width: 290px;
  height: 207px;
  color: #FFFFFF;
  border-radius: 30px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  background-color: #FFFFFF;

}

  
}
@media screen and (max-width: 968px) {
  .mobile-none{
    display: none;
  }
}