.answer-buttons{
    transition: 380ms;
    display: flex;
    align-items: center;
   padding-left: 18px;
    cursor: pointer;
    width: 350px;
    height: 50px;
    background-color: #F8F8F8;
    color: #333333;
    font-size: 16px;
    font-family: "ProductSans-Light";
    border-radius: 25px;
    box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
    letter-spacing: 0px;
    color: var(--blue);
}
.answer-buttons-clicked{
    transition: 380ms;
    display: flex;
    align-items: center;
   padding-left: 18px;
    cursor: pointer;
    width: 350px;
    height: 50px;
    background-color: var(--navyBlue);
    color: #FFFFFF;
    font-size: 16px;
    font-family: "ProductSans-Light";
    border-radius: 25px;
    box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
     letter-spacing: 0px;
}

  



.answer-buttons:hover{
    box-shadow: 0px 7px 15px -4px rgba(0, 0, 0, 0.46);
    
}


@media screen and (max-width:1080px) {

    .answer-buttons{
       
        transition: 180ms;
        display: flex;
        align-items: center;
       padding-left: 18px;
        cursor: pointer;
        width: 97%;
        height: 50px;
        background-color: #F8F8F8;
        color: var(--blue);
        font-size: 16px;
        font-family: "ProductSans-Light";
        border-radius: 25px;
        box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
        letter-spacing: 0.2px;
    }
    .answer-buttons-clicked{
    
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        width: 97%;
        height: 50px;
        background-color: var(--navyBlue);
        color: #FFFFFF;
        font-size: 16px;
        font-family: "ProductSans-Light"; 
        border-radius: 25px;
        box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
        letter-spacing: 0.2px;
    }
    
    
    .answer-buttons:hover{
        box-shadow: 0px 7px 15px -4px rgba(0, 0, 0, 0.46);
        
    }
    
}